import React, {useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ErrorToast from './ErrorToast';
import {LoginContext} from './LoginContext';

const WallList = () => {
  const [walls, setWalls] = useState();
  const [error, setError] = useState();
  const {authHeader} = useContext(LoginContext);

  useEffect(() => {
    fetch(`/api/walls/`, {headers: authHeader}).then(
      r => r.ok ? 
      r.json().then(setWalls) :
      r.text().then(setError)
    ).catch(setError);
  }, [authHeader]);

  const remove = ({id, name}) => {
    window.confirm(`Delete wall "${name}"?`) &&
      fetch(`/api/admin/walls/${id}`, {
        method: "DELETE",
        headers: authHeader
      }).then(r => r.ok ?
        setWalls(walls.filter(wall => wall.id !== id)) :
        r.text().then(setError) 
      )
      .catch(setError);
  };

  const toggleArtrock = wall => {
    //console.log(`fetching /api/admin/walls/${wall.id}`);
    fetch(`/api/admin/walls/${wall.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...authHeader
      },
      body: JSON.stringify({ artrock: !wall.artrock })
    }).then(r=> {
      if(r.ok) {
        wall.artrock = !wall.artrock;
        setWalls([...walls]);
      } else {
        console.log(r);
        r.text().then(setError);
      }
    }).catch(setError);
  };

  return (
    <div className='container'>
      <h1>Walls</h1>
      {
        walls ?
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {
                walls.map(wall => (
                  <tr key={wall.id}>
                    <td>{wall.name}</td>
                    <td>{wall.id}</td>
                    <td>
                      <div className="form-check">
                      <input className="form-check-input" type="checkbox" id='artockToggle' checked={wall.artrock} onChange={status => toggleArtrock(wall)}/> 
                      <label className="form-check-label" for='artrockToggle'>Artrock</label>
                      </div>
                    </td>
                    <td>
                      <Link to={`/walls/${wall.id}`}>
                        <button className='btn btn-primary mx-1'>Edit</button>
                      </Link>
                      <button className='btn btn-danger mx-1' onClick={() => remove(wall)}>Delete</button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      }
      <ErrorToast error={error}/>
    </div>
  );
};

const EditWall = () =>  {
  const {id} = useParams();
  const [wall, setWall] = useState();
  const [boulders, setBoulders] = useState();
  const [error, setError] = useState();
  const {authHeader} = useContext(LoginContext);

  useEffect(() => {
    fetch(`/api/walls/${id}`).then(
      r => r.ok ? 
      r.json().then(setWall) :
      r.text().then(setError)
    ).catch(setError);
  }, [id]);

  useEffect(() => {
    wall && fetch(`/api/walls/${wall.id}/boulders`).then(
      r => r.ok ? 
      r.json().then(setBoulders) :
      r.text().then(setError)
    ).catch(setError);
  }, [wall]);

  const remove = ({id, name}) => {
    window.confirm(`Delete boulder "${name}"?`) &&
      fetch(`/api/boulders/${id}`, {
        method: "delete",
        headers: authHeader
      }).then(r => r.ok ? 
        setBoulders(boulders.filter(boulder => boulder.id !== id)) :
        setError(r.statusText)
      )
      .catch(setError);
  };

  return wall && (
    <div className='container'>
      <h1>Boulders for wall "{wall.name}"</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th/>
          </tr>
        </thead>
        {
          boulders ?
            <tbody>
              {
                boulders.map(boulder => (
                  <tr key={boulder.id}>
                    <td>{boulder.name}</td>
                    <td>{boulder.id}</td>
                    <td>
                      <button className='btn btn-danger mx-1' onClick={() => remove(boulder)}>Delete</button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
            :
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
        }
      </table>
      <ErrorToast error={error}/>
    </div>
  );


};

export { WallList, EditWall };
